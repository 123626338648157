html {
  --bg: #292933; }

.nav_header {
  background-color: var(--bg); }

.highlight {
  pre {
    padding: 1rem; } }

.post_link, .post_title {
  font-weight: bold; }
